<template>
  <div class="app-header-nav">
    <Input
      v-model="value"
      search
      placeholder="输入商品名称/规格/会面码等搜索"
    />
    <Button class="btn" @click="search()">搜索</Button>
  </div>
</template>

<script>
export default {
	inject: ['reload'], // 注入依赖
  name: 'AppHeaderNav',
  data () {
    return {
      value: '',
    }
  },
  methods: {
    search () {
      if (!this.value) {
        return
      }

      this.$router.push({
        path: '/category/sub', // params对应路由跳转为name方式
        query: {
          productName: this.value,
        },
      }).then(() => {
        this.reload()
      })
    },
  },
  setup () {},
}
</script>

<style scoped lang="less">
.app-header-nav {
  width: 820px;
  display: flex;
  justify-content: space-around;
  padding-left: 40px;
  position: relative;
  z-index: 998;
}
/deep/ .ivu-input {
  padding-left: 8px;
  border: 2px solid #d12b23;
  height: 36px;
  line-height: 36px;
  width: 100%;
}
.btn {
  // display: block;
  width: 144px;
  background: #d12b23;
  height: 37px;
  font-weight: 450;
  font-size: 16px;
  color: #fff;
  text-align: center;
  line-height: 40px;
  border: 1px solid #d12b23;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
